body #aboutMebg {
  background-image: url("space.jpeg");
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  /* position: relative */
}

image-bg {
  position: static;
  z-index: 3;
}

.aboutMeContainer {
  /* background-color: black; */
  width: 500px;
  opacity: 1;
  box-shadow: 10px 10px 50px rgba(55, 20, 20, 0.37);
  padding-top: 65px;
  padding-bottom: 65px;
  border-radius: 50px;
  /* background-image: url("../../public/images/aboutMe.png"); */

  background-color: rgba(13, 11, 47, 0.86);
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* justify-content: space-between; */
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: auto;
}
.aboutMeContainer p {
  width: 75%;
}
#aboutMe {
  width: 45%;
}

.wordsAndStuff {
  padding-top: 25px;
  z-index: 3;
  opacity: 1;
}

#lavaPlanet {
  position: absolute;
  background-size: cover;
  border-radius: 50%;
  z-index: 1;
  height: 200px;
  width: 200px;
  background-image: url("../../public/images/LavaPlanet.jpeg");
  /* spinning */
  /* animation-name: spin; */
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  top: 150px;
  left: 10%;
  box-shadow: 3px 5px 10px rgba(101, 21, 21, 0.855);
  /* move */
  transform: translateX(0);
  animation: move1 5s linear infinite;
  transition: transform 0.1s ease-in-out;
  will-change: transform;

  transform: translateY(0);
  animation: move1 400s linear infinite; 
  transition: transform 0.1s ease-in-out;
  will-change: transform;
}

/* mobile frienldy */




/* @media (max-width: 500px) {
    #lavaPlanet{
        position: scroll;
        width: 300px;
        height:300px;
    }
    .aboutMeContainer{
         position: fixed; 
    
        width: 300px;
        height:300px;
    }

    #aboutMebg{
        position: fixed;
    } */

@keyframes move1 {
  0%,
  100% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }
  50% {
    transform: translateX(calc(100vw*0.4)) translateY(500px) rotate(360deg);
  }
}

/* .aboutMeContainerBG{
      opacity: .5;
  position: absolute;
  background-size: cover;
  border-radius: 50px;
  z-index: 3; */
/* height: 100%;  */
/* width: 100%;  */
/* background-image: url("aboutMe.jpg");
  background-attachment: fixed; */
/* background-size: cover; */

/* } */

#lavaPlanetText {
  text-align: center;
  margin-top: 45%;
  font-weight: 800;
}

/* .aboutMeImage {
  width: 400px;
  height: 100%;400

  overflow: hidden;
  position: absolute;
  opacity: 0.3;
  object-fit: cover;
} */


/* @media (max-width: 600px) {
  #lavaPlanet {
    width: 400px;
    height: 400px;
  }
  .aboutMeContainer {
    position: scroll;
    width: 400px;
    height: 400px;
  }
} */

@media only screen and (max-width:450px) {
    #lavaPlanet{
      width: 100px;
      height: 100px;
    }
  
}

#aboutMebg {
  background-image: url("space.jpeg"); /* Ensure correct path */
  background-attachment: fixed; /* This creates a basic parallax effect */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
}