.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.circle {
  width: 100px;

  height: 100px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  box-shadow: inset 0 0 30px #fff, inset 20px 0 30px #d7d938,
    inset -20px 0 30px #ff3407, inset 4px 7px 300px #ff0c00,
    inset -20px 0 300px #feff88, 0 0 50px #fff, -10px 0 80px #f0f,
    10px 0 80px #0ff;

  position: absolute;
  top: 30px;
  left: 55%;
  transform: translateX(0);
  animation: move 5s linear infinite;
  transition: transform 0.1s ease-in-out;
  will-change: transform;
  background: #000000;
}

@keyframes move {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(15px);
  }
}

.hero {
  position: relative;
}

.hero__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero__name {
  margin-top: -100px;
  text-shadow: 2px 2px 10px rgb(248, 86, 5);
  font-size: 50px;
}
.hero__title {
  text-align: center;
  font-size: 40px;
  padding-left: 20px;
  padding-right: 20px;
}
.hero{
  background-color: rgb(25, 22, 43);
  display:flex;
}